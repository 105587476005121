import React from 'react';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';
import {
  WaterproofMattressProtectorWarrantyHeroSection,
  WaterproofMattressProtectorWarrantyIntroSection,
  WaterproofMattressProtectorWarrantyContentSection,
} from '../../../components/sections';
import { CountryCode } from '../../../utils/constants';

const WaterproofMattressProtectorWarranty: React.FC = () => {
  return (
    <Layout countryCode={CountryCode.SG}>
      <SEO title="Waterproof Mattress Protector" />
      <WaterproofMattressProtectorWarrantyHeroSection />
      <WaterproofMattressProtectorWarrantyIntroSection />
      <WaterproofMattressProtectorWarrantyContentSection />
    </Layout>
  );
};

export default WaterproofMattressProtectorWarranty;
